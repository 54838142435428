export default function errTrad(code, lang){

    const err = {
        fr:[
            'code_0',
            'Code invalide',
            'Code déjà utilisé',
            'Session incorrect',
            'Email invalide',
            'Nom invalide',
            'Prénom invalide',
            'Vous devez avoir plus de 18 ans pour participer.',
            'Numéro de téléphonne invalide.',
            'Session introuvable',
        ],
        nl:[
            'code_0',
            'Ongeldige code',
            'Code reeds gebruikt',
            'Ongeldige sessie',
            'Ongeldig e-mailadres',
            'Ongeldig naam',
            'Ongeldig voornaam',
            'Je moet minstens 18 jaar zijn om deel te nemen',
            'Geen geldig telefoonnummer',
            'Ongeldige sessie',
        ]
    }

    if(lang === 'nl'){
        return err.nl[code];
    }else{
        return err.fr[code]
    }
}