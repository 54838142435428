import { useEffect, useState } from "react";
import { getSession, getUserStatus, requestApi } from "../../utils/utils";
import { getLang } from "../../utils/utils";
import errTrad from "../../utils/err_code";

export default function Form(){

    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birdthday, setBirthDay] = useState('');
    const [msg, setMsg] = useState('');
    const [keyCode, setKeyCode] = useState('');
    const [tel, setTel] = useState('+32');
    const [lang, setLang] = useState('nl');

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);

    const loadStatus = async () => {
        const rep = await getUserStatus();
        const key = await getSession();
        setKeyCode(key);
        setStatus(rep);
        console.log({status : rep});
    }

    useEffect(()=>{
        loadStatus();
    },[status]);

    const submit = async (e) =>{
        e.preventDefault();

        const res = await requestApi('/completeForm', 'post', {
            birthday: birdthday,
            firstName: firstName,
            lastName: lastName,
            email: email,
            keyCode: keyCode,
            tel: tel,
        });

        if(!res.data.success){
            const msgRes = errTrad(res.data.msg, lang);
            setMsg(msgRes);
        }else{
            window.location.href = '/start';
        }
    }

    if(status === 'none'){
        window.location.href = '/';
        return null;
    }

    if(status === 'play'){
        window.location.href = '/game';
    }

    if(lang === 'nl'){
        return(
            <div className="container_custom home_container">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-6 mt-3">
                            <form onSubmit={submit}>
                            <h1 className="c-white">
                                Deelnemen
                            </h1>
                            <label htmlFor="email" className="c-white mt-3">E-mail adres</label>
                            <input id="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required placeholder="E-mail adres" type="email" className="form-control"/>
                            <div className="row">
                                <div className="col col-12 col-md-6">
                                    <label htmlFor="nom" className="mt-3 c-white">Naam</label>
                                    <input required id="nom" type="text" value={lastName} onChange={(e)=>{setLastName(e.target.value)}} placeholder="Naam" className="form-control" />
                                </div>
                                <div className="col col-12 col-md-6">
                                    <label htmlFor="prenom" className="c-white mt-3">Voornaam</label>
                                    <input required type="text" id="prenom" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} placeholder="Voornaam" className="form-control" />
                                </div>
                                <div className="col col-12">
                                    <label htmlFor="tel" className="c-white mt-3">Telefoonnummer</label>
                                    <input required type="tel" id="tel" value={tel} onChange={(e)=>{setTel(e.target.value)}} placeholder="Telefoonnummer" className="form-control" />
                                </div>
                            </div>
                            <label htmlFor="birdthday" className="label mt-3 c-white">Geboortedatum</label>
                            <input required type="date" onChange={(e)=>{setBirthDay(e.target.value)}} value={birdthday} id="birdthday" className="form-control mt-3" />
                            <div className="row mt-3">
                                <div className="col col-1">
                                    <input type="checkbox" style={{height: '1.6em', width: '1.6em'}} required />
                                </div>
                                <div className="col col-11">
                                    <span style={{fontSize: '1.6m'}}>Ik ga akkoord met de <a href="/conditions" style={{color: 'black'}}>algemene voorwaarden</a></span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col col-1">
                                    <input type="checkbox" style={{height: '1.6em', width: '1.6em'}} required />
                                </div>
                                <div className="col col-11">
                                <span style={{fontSize: '1.6m'}}>Ik ga akkoord met het <a href="/conditions" style={{color: 'black'}}>privacybeleid</a> van Java</span>
                                </div>
                            </div>
                            <label className="error_msg mt-3">{msg}</label>
                            <div className="col col-12 mt-3 mb-5">
                                <button className="btn btn_code" type="submit">
                                    <strong>Spelen</strong>
                                </button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    else{
        return(
            <div className="container_custom home_container">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-6 mt-3">
                            <form onSubmit={submit}>
                            <h1 className="c-white">
                                Je tente ma chance
                            </h1>
                            <label htmlFor="email" className="c-white mt-3">Adresse email</label>
                            <input id="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required placeholder="Adresse Email" type="email" className="form-control"/>
                            <div className="row">
                                <div className="col col-12 col-md-6">
                                    <label htmlFor="nom" className="mt-3 c-white">Nom</label>
                                    <input required id="nom" type="text" value={lastName} onChange={(e)=>{setLastName(e.target.value)}} placeholder="Nom" className="form-control" />
                                </div>
                                <div className="col col-12 col-md-6">
                                    <label htmlFor="prenom" className="c-white mt-3">Prénom</label>
                                    <input required type="text" id="prenom" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} placeholder="Prénom" className="form-control" />
                                </div>
                                <div className="col col-12">
                                    <label htmlFor="tel" className="c-white mt-3">Téléphonne</label>
                                    <input required type="tel" id="tel" value={tel} onChange={(e)=>{setTel(e.target.value)}} placeholder="Téléphone" className="form-control" />
                                </div>
                            </div>
                            <label htmlFor="birdthday" className="label mt-3 c-white">Date de naissance</label>
                            <input required type="date" onChange={(e)=>{setBirthDay(e.target.value)}} value={birdthday} id="birdthday" className="form-control mt-3" />
                            <div className="row mt-3">
                                <div className="col col-1">
                                    <input type="checkbox" style={{height: '1.6em', width: '1.6em'}} required />
                                </div>
                                <div className="col col-11">
                                    <span style={{fontSize: '1.6m'}}>J'accepte les <a href="/conditions" style={{color: 'black'}}>condition générales</a></span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col col-1">
                                    <input type="checkbox" style={{height: '1.6em', width: '1.6em'}} required />
                                </div>
                                <div className="col col-11">
                                <span style={{fontSize: '1.6m'}}>J'accepte la politique de Java sur la <a href="/conditions" style={{color: 'black'}}>vie privée</a></span>
                                </div>
                            </div>
                            <label className="error_msg mt-3">{msg}</label>
                            <div className="col col-12 mt-3 mb-5">
                                <button className="btn btn_code" type="submit">
                                    <strong>Jouer</strong>
                                </button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}