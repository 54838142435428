import Cookies from "js-cookie";
import axios from 'axios';

export const uri_api = 'https://api.web-treeosk.online';

export function requestApi(path, method, params) {
    if (method === 'post') {
        return axios.post(uri_api + path, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
    } else if (method === 'get') {
        return axios.get(uri_api + path, { params: params });
    } else {
        return Promise.reject(new Error('Méthode de requête invalide'));
    }
}

export async function encodeCookies(key, val) {
    await Cookies.set(key, val);
}

export async function removeCookies(key){
    await Cookies.remove(key);
}

export async function setSession(key_code) {
    await encodeCookies('session', key_code);
}

export async function sessionDestroy(){
    await removeCookies('session');
}

export async function getSession() {
    return Cookies.get('session');
}

export async function userIsOnSession(){
    return await getSession() !== undefined;
}

export async function getUserStatus() {

    if(!userIsOnSession()){
        console.log('no session');
        return 'none';
    }

    const sess = await getSession();

    const res = await requestApi('/getSession?keyCode=' + sess, 'get', {
        keyCode: sess,
    });

    if(res.data.success){
        return res.data.data.status;
    }else{
        return 'none';
    }
}

export async function getLang(){
    const lang =  await Cookies.get('lang');
    if(!lang){
        await setLang('nl');
    }
    return lang;
}

export async function setLang(lang){
    Cookies.set('lang', lang);
}

export function randomInt(min, max){
    return Math.floor(Math.random()*(max-min) + min);
}

export async function changeLang(){
    const lang = await getLang();

    if(lang === 'fr'){
        await setLang('nl');
    }else{
        await setLang('fr');
    }

    return true;
}