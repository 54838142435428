import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { getUserStatus } from "../../utils/utils";
import { getLang } from "../../utils/utils";

function PageNotFound(){

    const location = useLocation();
    const { pathname } = location;
    const [lang, setLang] = useState('nl');

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);
    
    const verifyStatus = async()=>{
        const status = await getUserStatus();
        console.log(status);
        if(status === 'started' && pathname === '/form'){
            window.location.href = '/form';
        }
    }

    useEffect(()=>{
        verifyStatus();
    },[]);

    return(
        <div className="container_custom home_container">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-md-6">
                        <h1 className="text-center mt-3 c-white">
                            {(lang === 'fr' ? 'Page introuvable' : 'Pagina niet gevonden')}
                        </h1>
                        <button className="btn_prizes mt-5" onClick={()=>{window.location.href='/'}}>
                            {(lang === 'fr' ? 'Revenir à la page d\'accueil' : 'Terug naar de startpagina')} 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;