import React, { useState, useEffect, useRef } from "react";
import { getSession, getUserStatus, requestApi, uri_api } from "../../utils/utils";
import GameContent from './GameContent';
import GameJava from "./classJS/Game";
import { getLang } from "../../utils/utils";

export default function Game() {
    const game = useRef(null);
    const [status, setStatus] = useState('');
    const [renderCount, setRenderCount] = useState(0);
    const [stateLoad, setStateLoad] = useState(7);
    const [compteur, setCompteur] = useState(3);
    const [lang, setLang] = useState('nl');
    const [gain_win, set_gain_win] = useState(null);

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);

    useEffect(() => {
        if(stateLoad === 5){
            const interval = setInterval(() => {
                setCompteur((prevCompteur) => prevCompteur - 1);
            }, 1000);
    
            if (compteur === 0 && !game.current && stateLoad === 5) {
                setTimeout(() => {
                    game.current = new GameJava();
                    setCompteur('');
                    setStateLoad(6);
                }, 1000);
            }
    
            return () => clearInterval(interval);
        }
    }, [compteur, stateLoad]);

    const loadStatus = async () => {
        const rep = await getUserStatus();
        setStatus(rep);
    };

    useEffect(() => {
        const handleMouseMove = (event) => {

            const gameContainer = document.getElementById('game');
            if(!gameContainer){
                return;
            }

            const rect = gameContainer.getBoundingClientRect();
        
            let controllerX;

            if(event.clientX){
                controllerX = ((event.clientX - rect.left) / rect.width * 100) - 2.5;
            }

            else{
                controllerX = ((event.touches[0].clientX - rect.left) / rect.width * 100) - 2.5;
            }

            if(controllerX <= 0){
                controllerX = 0;
            } else if(controllerX >= 95){
                controllerX = 95;
            }
            if(stateLoad === 6){
                game.current.controller.position.x = controllerX;
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchmove', handleMouseMove);
        };
    }, [stateLoad]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRenderCount((prevCount) => prevCount + 1);
            if(game.current && game.current.over){
                updatePlayer();
            }
        }, 20);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        loadStatus();
    }, []);

    const updatePlayer = async () =>{
        const res = await requestApi('/updateScoreAndTime', 'post', {
            score: game.current.score,
            time: game.current.timer,
            keyCode: await getSession(),
            isPossibleWinner : (game.current.orsRamasse === 3)
        });
        if(res.data.success){
            setStateLoad(7);
            set_gain_win(res.data.data.gain);
        }else{
            window.location.href = '/error';
        }
    }

    // Vérifier si le navigateur prend en charge le mode plein écran
    const isFullScreenSupported = () => {
        return document.fullscreenEnabled || document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled || document.msFullscreenEnabled;
    };

    // Afficher un message suggérant de passer en plein écran
    const suggestFullScreen = () => {
        let message = "Pour une meilleure expérience, veuillez passer en mode plein écran, et tourner votre téléphone.";
        if(lang === 'nl'){
            message = "Voor een betere ervaring, schakel over naar de volledig scherm modus en draai uw telefoon.";
        }
        alert(message);
        //requestFullScreen(document.body);
    };

    useEffect(() => {
        // Vérifier si le mode plein écran est pris en charge et afficher la suggestion
        if (isFullScreenSupported()) {
            suggestFullScreen();
        }
    }, []);

    /*
    if(status === 'none' || status === 'finish'){
        window.location.href = '/';
        return null;
    }
    */

    if(stateLoad >= 7){
        setTimeout(()=>{
            window.location.href = 'https://www.javacoffee.be/nl/thuis';
        }, 8000);
    }

    if(stateLoad <= 5 || stateLoad === 7){
        return(
            <div className="container_custom home_container">
                <div id="game" className="position-absolute top-50 start-50 translate-middle rounded">
                <div className="position-absolute top-50 start-50 translate-middle pulseCompteur c-white">
                    {(stateLoad === 5) ? compteur : ''}
                </div>
                    <div className="container">
                        <div className="row" style={{marginTop: '5%', zIndex: 50}}>
                            <div className="col col-3 text-end c-pointor">
                                {(stateLoad > 1 && stateLoad < 5) ? <img width={'50%'} src={uri_api + '/assets/fleche.png'} style={{transform: 'rotate(180deg)', marginTop: '75%'}} onClick={()=>{setStateLoad(stateLoad - 1)}} /> : ''}
                            </div>

                            <div className="col col-6 text-center">
                                {((stateLoad >= 1 && stateLoad < 5)) ? <img style={{marginTop: '15%'}} width={'70%'} src={uri_api + '/assets/preload_' + stateLoad + '-' + lang + '.png'}/> : ''}
                                {(stateLoad === 7 && gain_win !== null) ? <img style={{marginTop: '5%'}} width={'90%'} src={uri_api + '/assets/preload_' + stateLoad + '-' + gain_win + '-' + lang + '.png'}/> : ''}
                            </div>

                            <div className="col col-3 text-start c-pointor">
                                {(stateLoad >= 1 && stateLoad < 5) ? <img width={'50%'} src={uri_api + '/assets/fleche.png'} style={{marginTop: '75%'}} onClick={()=>{setStateLoad(stateLoad + 1)}} /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if(game.current && game.current.over){
        return null;
    }

    return (
        <div className="container_custom home_container">
            <div id="game" className="position-absolute top-50 start-50 translate-middle rounded">
                <div className={"title_ors-" + lang + " position-absolute " + (game.current.stateOrs ? '' : 'no-display')}>
                </div>
                <div className={"position-absolute score" + (game.current.orsRamasse == 3 ? ' border-ors' : ' border-argent') + (game.current.orsRamasse === 3 ? ' shadow-gold' : '')} style={{left:'3%', top: '4%', width: '10%', height: 10*16/9 + '%'}}>
                        {(game.current.normal < 10) ? 
                        <h3 style={{marginTop: '25%', fontSize: '1.2vw'}}>
                            SCORE <br />{game.current.score}
                        </h3>
                        :
                        <>
                        <div className="text-center" style={{padding: '4%', fontSize: '1vw'}}>
                        {(lang === 'fr') ? <>GRAINS <br/> EN ORS</> : <>GOUDEN <br/> BONEN</>}
                        <br />

                                <img style={{width: '33%', height: 'auto'}} src={(game.current.orsRamasse > 0) ? uri_api + '/assets/ors.png' : uri_api + '/assets/ors_no_recup.png'} />
                                <img style={{width: '33%', height: 'auto'}} src={(game.current.orsRamasse > 1) ? uri_api + '/assets/ors.png' : uri_api + '/assets/ors_no_recup.png'} />
                                <img style={{width: '33%', height: 'auto'}} src={(game.current.orsRamasse > 2) ? uri_api + '/assets/ors.png' : uri_api + '/assets/ors_no_recup.png'} />
                        </div>
                        </>
                        }
                        
                </div>
                <div className="position-absolute timer" style={{right: '3%', top: '4%', height: 10*16/9 + '%', width: '10%', border: 'solid 0.4em silver', fontSize: '1.2vw'}}>
                    <h3 style={{marginTop: '35%', fontSize: '1.2vw'}}>
                        {Number((45 - (game.current.timer / 10)).toFixed(1)) + 'S'}
                    </h3>
                </div>
                <div className={"controller sprite position-absolute " + game.current.controller.state} 
                    id="controller" 
                    style={{
                        zIndex: 10,
                        top: game.current.controller.position.y + '%',
                        left: game.current.controller.position.x + '%',
                        height: game.current.controller.size.heigth + '%',
                        width: game.current.controller.size.width + '%'
                        }}>
                </div>
                <div
                id="progress"
                className={"position-absolute " + (game.current.orsHaveSpawn ? 'shadow-gold' : '')}
                style={{
                    top: '95%',
                    width: '60%',
                    height: '3%',
                    left: '20%',
                    backgroundColor: 'white',
                    borderRadius: '0.8em',
                    overflow: 'hidden'
                }}
                >
                <div
                    style={{
                    background: 'gold',
                    height: '100%',
                    width: (game.current.score * 2) + '%',
                    borderRadius: '0.8em',
                    overflow: 'hidden'
                    }}
                ></div>
                </div>
                {(stateLoad === 6) ? <GameContent graines={game.current.graines} key={renderCount} /> : ''}
            </div>
        </div>
    )
}