import { uri_api } from "../../utils/utils";
import { getLang } from "../../utils/utils";
import { useState, useEffect } from "react";

export default function (){

    const [lang, setLang] = useState('nl');

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);

    return(
        <div className="container_custom home_container">
            <div className="container">
                <div className="col col-12 col-md-6 text-center">
                    <div className="row c-white">
                        <div className="col col-6">
                            <img className="mt-5" src={uri_api + "/assets/gain_1.png"} width={"60%"} />
                        </div>
                        <div className="col col-6">
                            <h3 style={{marginTop: '30%'}}>{(lang === 'fr' ? 'La machine à café JURA ENA 8' : 'Een JURA ENA 8 koffiemachine')} </h3>
                        </div>
                        <div className="col col-6">
                            <img className="mt-5" src={uri_api + "/assets/gain_3.png"} width={"60%"} />
                        </div>
                        <div className="col col-6">
                            <h3 style={{marginTop: '30%'}}>{(lang === 'fr') ? 'Un assortiment de café et un verre JAVA' : 'Een assortiment JAVA koffie en een thermobeker  '}</h3>
                        </div>
                        <div className="col col-6">
                            <img className="mt-5" src={uri_api + "/assets/gain_2.png"} width={"60%"} />
                        </div>
                        <div className="col col-6">
                            <h3 style={{marginTop: '30%'}}>{(lang === 'nl') ? 'Een korting van 0,50€ op uw volgende aankoop' : 'Une réduction de 0,50€ sur votre prochain achat'}</h3>
                        </div>
                        <button className="btn btn_prizes mt-5" onClick={()=>{window.location.href = '/'}}>
                            {(lang === 'fr' ? 'Revenir à la page d\'accueil' : 'Terug naar de startpagina')} 
                        </button>
                    </div>
                </div>
                <div className="col col-12 col-md-6">
                    
                </div>
            </div>
        </div>
    )
}