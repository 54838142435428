export default function GameContent({graines}){

    if(!graines){
        return null;
    }

    return(
        <>
        {graines.map((graine, index)=>{
            if(graine.status === "inactive"){
                return null;
            }
            return(
                <div style={{height: (graine.size.heigth * 16/9) + '%',
                            width: graine.size.width + '%',
                            left: graine.position.x + '%',
                            top: graine.position.y + '%',
                            rotate: graine.angle + 'deg',
                            zIndex: 5}} key={index} className={"sprite " + graine.type}>

                </div>
            )
        })}
        </>
    )
}