import { uri_api } from '../../utils/utils';

export default function Conditions(){

    return(
        <div className="container_custom home_container">
            <a href='/form' style={{height: '5%'}}>
                Retour
            </a>
            <embed  aria-haspopup={true} src={uri_api + '/assets/conditions.pdf'} type="application/pdf" width="100%" height="100%" toolbar="false"/>
        </div>
    );
}