import { uri_api } from "../../../utils/utils";
import Position from "./Position";
import Size from "./Size";
import Sprite from "./Sprite";

class Graine extends Sprite{
    constructor(positionX, type){
        super(new Position(positionX, 0), new Size(8, 8), uri_api + '/assets/graine.png');
        this.status = 'active';
        this.inter = setInterval(()=>{this.move()}, 20);
        this.angle = 0;
        this.type = type;
    }

    move(){
        this.position.y += 1;
        this.angle += 3;
        if(this.position.y >= 92){
            this.changeState();
            clearInterval(this.inter);
        }
    }

    changeState(){
        this.status = "inactive";
        clearInterval(this.inter);
    }
}

export default Graine;