import Position from "./Position";
import Size from "./Size";
import Sprite from "./Sprite";
import { uri_api } from "../../../utils/utils";

class Controller extends Sprite{
    constructor(){
        super(new Position(47.5, 76.8), new Size(5, 16.8), uri_api + '/assets/controller.png');
        this.state = 'normal';
    }

    move(positionX){
        if(positionX <= 0){
            this.position.positionX = 0;
        }else if(positionX >= 100){
            this.position.positionX = 100;
        }else{
            this.position.x = positionX;
        }
    }

    ramasse(){
        this.state = 'scale';
        setTimeout(()=>{
            this.state = 'normal';
        }, 300);
    }

    malus(){
        this.state = 'malus-controller';
        setTimeout(()=>{
            this.state = 'normal';
        }, 300);
    }
}

export default Controller;