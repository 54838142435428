import { randomInt } from "../../../utils/utils";
import Controller from "./Controller";
import Graine from "./Gaine";

class GameJava {
    constructor() {
        this.over = false;
        this.stateOrs = false;
        this.orsRamasse = 0;
        this.orsHaveSpawn = false;
        this.normal = 0;
        this.score = 0;
        this.graines = [];
        this.timer = 0;
        this.controller = new Controller();
        this.inter = setInterval(() => {
            this.next();
        }, 100);
    }

    next() {
        this.isOver();
        ++this.timer;
        const random = randomInt(1, 40);
        if (random === 15 || random === 25) {
            this.addGraine('graine');
        }
        else if(random === 20){
            this.addGraine('malus');
        }
        for(let i = 0; i < this.graines.length; i++){
            const graine = this.graines[i];
            if(graine.status === "active" && this.controller.collision(graine)){
                switch(graine.type){
                    case 'graine':
                        this.score += 5;
                        ++this.normal;
                        this.controller.ramasse();
                        this.graines = this.graines.filter((g, index) => index !== i);
                        i--;
                        graine.changeState();
                        if(this.score === 50 && !this.orsHaveSpawn){
                            this.spawnOrs();
                        }
                        break;
                    case 'malus':
                        this.score -= 5;
                        this.controller.malus();
                        this.graines = this.graines.filter((g, index) => index !== i);
                        i--;
                        graine.changeState();
                        break;
                    case 'ors':
                        ++this.orsRamasse;
                        this.score += 30;
                        this.controller.ramasse();
                        this.graines = this.graines.filter((g, index) => index !== i);
                        i--;
                        graine.changeState();
                        break;
                }
            }
        }
    }

    addGraine(type) {
        const posX = randomInt(1, 95);
        this.graines.push(new Graine(posX, type));
    }

    isOver() {
        if (this.timer >= 450) {
            this.over = true;
            clearInterval(this.inter);
        }
    }

    spawnOrs(){
        this.stateOrs = true;
        this.orsHaveSpawn = true;
        for(var i = 0; i < 3; ++i){
            setTimeout(()=>{
                this.addGraine('ors');
            }, randomInt(1, 8000));
        }
        setTimeout(()=>{
            this.stateOrs = false;
        }, 10000);
        setTimeout(()=>{
            this.over = true;
        }, 10000);
    }
}

export default GameJava;