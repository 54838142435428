class Sprite{
    constructor(position, size, background_uri){
        this.background_uri = background_uri;
        this.position = position;
        this.size = size;
    }

    collision(sprite) {
        return (
            this.position.x < sprite.position.x + sprite.size.width &&
            this.position.x + this.size.width > sprite.position.x &&
            this.position.y < sprite.position.y + sprite.size.heigth &&
            this.size.heigth + this.position.y > sprite.position.y
        )
    }
    
}

export default Sprite;