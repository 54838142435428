import { useEffect, useState } from "react";
import { changeLang, getLang, getUserStatus, requestApi, setSession, uri_api } from "../../utils/utils";
import errTrad from "../../utils/err_code";

const Home = () =>{

    const [msg, setMsg] = useState('');
    const [code, setCode] = useState('');
    const [lang, setLang] = useState('nl');
    const [status, setStatus] = useState('none');
    const [btnState, setBtnState] = useState(false);

    const subCode = async (e) => {
        e.preventDefault();

        setBtnState(true);

        const res = await requestApi('/generateSession', 'post', {
            code: code,
        });

        if(res.data.success){
            setSession(res.data.data.keyCode);
            window.location.href = '/form';
        }
        else{
            const msgRes = errTrad(res.data.msg, lang);
            setBtnState(false);
            setMsg(msgRes);
        }
    }

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    const loadStatus = async() => {
        const res = await getUserStatus();
        setStatus(res);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);

    useEffect(()=>{
        loadStatus();
        console.log(status);
    },[]);

    if(status === 'started'){
        window.location.href = '/form';
        return null;
    }

    if(status === 'form_completed'){
        window.location.href = '/game';
        return null;
    }

    if(lang === 'nl'){
        return(
            <div className="container_custom home_container">
                <div className="container mt-5">
                    <h1 style={{color: 'black', textAlign: 'center'}}>
                        Helaas deze wedstrijd is afgelopen
                    </h1>
                </div>
            </div>
        );
    }

    else{
        return(
            <div className="container_custom home_container">
                <div className="container mt-5">
                    <h1 style={{color: 'black', textAlign: 'center'}}>
                        Malheureusement ce concours est terminé
                    </h1>
                </div>
            </div>
        );
    }
}

export default Home;