import { useEffect, useState } from "react"
import { getSession, requestApi } from "../../utils/utils";
import { getLang } from "../../utils/utils";

export default function StartPage(){

    const [first_name, set_first_name] = useState('');
    const [lang, setLang] = useState('nl');

    const loadLang = async () => {
        const lang = await getLang();
        setLang(lang);
    }

    useEffect(()=>{
        loadLang();
    }, [lang]);

    const loadFirstName = async () => {
        const res = await requestApi('/getFirstName', 'post', {keyCode: await getSession()});
        if(res.data.success){
            set_first_name(res.data.data.first_name);
        }
    }

    useEffect(()=>{
        loadFirstName();
    }, []);

    if(lang === 'nl'){

        return(
            <div className="container_custom home_container_start">
                <div className="container c-brun">
                    <div className="row mt-5">
                        <div className="col col-12 col-md-6">
    
                        </div>
                        <div className=" col col-12 col-md-6">
                            <h1 className="fs-1">
                                Dag {first_name},
                            </h1>
                            <p className="mt-5 fs-3">
                                Vul het <strong>JAVA pakje</strong> met koffiebonen. Pakje vol? Vang dan de <strong>3 gouden koffiebonen</strong>. <br/>Snel, voordat de tijd om is. Je ontdekt meteen je prijs
                            </p>
                            <button className="btn btn_start w-50 mt-5" onClick={()=>{window.location.href =  '/game'}}>
                                Volgende
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else{
    return(
        <div className="container_custom home_container_start">
            <div className="container c-brun">
                <div className="row mt-5">
                    <div className="col col-12 col-md-6">

                    </div>
                    <div className=" col col-12 col-md-6">
                        <h1 className="fs-1">
                            Bonjour {first_name},
                        </h1>
                        <p className="mt-5 fs-3">
                            Collectez les grains de café pour <strong>remplir le paquet de JAVA</strong> et faire <strong>apparaître les grains de café en or</strong>.
                            <strong>Attrapez 3 grains de café en or</strong> avant le temps imparti et découvrez tout de suite votre cadeau !
                        </p>
                        <button className="btn btn_start w-50 mt-5" onClick={()=>{window.location.href =  '/game'}}>
                            C'est parti!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}