import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Form from './components/form/Form';
import Game from './components/game/Game';
import Conditions from './components/Conditions/Conditions';
import StartPage from './components/game/StartPage';
import Cadeaux from './components/Cadeaux/Cadeaux';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/form' element={<Form/>}/>
        <Route path='*' element={<PageNotFound />} />
        <Route exact path='/game' element={<Game/>}/>
        <Route exact path='/conditions' element={<Conditions/>}></Route>
        <Route exact path='/start' element={<StartPage/>}></Route>
        <Route exact path='/prizes' element={<Cadeaux/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
